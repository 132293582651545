import { render, staticRenderFns } from "./commonInfoSetting.vue?vue&type=template&id=e583f72a&scoped=true"
import script from "./commonInfoSetting.vue?vue&type=script&lang=js"
export * from "./commonInfoSetting.vue?vue&type=script&lang=js"
import style0 from "./commonInfoSetting.vue?vue&type=style&index=0&id=e583f72a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e583f72a",
  null
  
)

export default component.exports