<!--设置管理模块--通知设置管理页--公共指南-->
<template>
  <div id="commonInfoSetting" v-loading="loading" :element-loading-text="$t('doorequipment.a12')">
    <!-- 查询栏 -->
    <div class="header">
      <div>
        <a-input v-model="form.info" :placeholder="$t('commonInfoSetting.a1')" style="width:180px;margin-right:15px"/>
        <a-input v-model="form.updateUser" :placeholder="$t('commonInfoSetting.a2')" style="width:180px"/>
      </div>
      <div>
        <a-button type="primary" @click="inquire" style="width:120px">{{$t('commonInfoSetting.a3')}}</a-button>
      </div>
    </div>
    <!-- 表格数据 -->
    <div class="main">
      <a-table 
        :row-selection="allrowSelection"
        :columns="columns" 
        :dataSource="data" 
        :pagination="pagination"
        :scroll="size" 
      > 
      </a-table>         
    </div>
    <!-- 底部功能栏 -->
    <div class="footer">
      <a-popconfirm
          :title="$t('commonInfoSetting.a4')"
          :ok-text="$t('commonInfoSetting.a5')"
          :cancel-text="$t('commonInfoSetting.a6')"
          @confirm="onDelete"
          @cancel="onCancel"
        >
        <a-button type="primary" class="but">{{$t('commonInfoSetting.a7')}}</a-button>
      </a-popconfirm>   
      <a-button type="primary" class="but" @click="onAdd">{{$t('commonInfoSetting.a11')}}</a-button>   
      <a-button type="primary" class="but" @click="onChange">{{$t('commonInfoSetting.a12')}}</a-button>         
    </div>
    <!-- 添加/变更弹框 -->
    <a-modal 
          v-model="visible" 
          :title="name" 
          width=600px           
          @ok="handleOk"
          @cancel="cancel"
          :destroyOnClose="true"
          v-drag
        >
        <a-form-model ref="ruleForm" :model="infoForm">
          <div class="form_row">
            <div class="form_col">
              <a-form-model-item :label="$t('commonInfoSetting.a8')">
                <a-select v-model="infoForm.type" style="width: 120px">
                  <a-select-option v-for="item in options" :value="item.type" :key="item.key">
                    {{item.desc}}
                  </a-select-option>
                </a-select>              
              </a-form-model-item>
            </div>
            <div class="form_col">
              <a-form-model-item :label="$t('commonInfoSetting.a9')" prop="date1">
                <a-range-picker v-model="infoForm.ranges"/>
              </a-form-model-item>
            </div>
            <div class="form_col">
              <a-form-model-item >
                <a-checkbox v-model="infoForm.important">{{$t('commonInfoSetting.a10')}}</a-checkbox>
              </a-form-model-item>
              </div>
          </div>     
          <div class="form_row">
            <div class="form_col">    
              <a-form-model-item :label="$t('commonInfoSetting.a1')" prop="date1">
                <a-textarea class="textarea" v-model="infoForm.msg" :auto-size="{ minRows: 6, maxRows: 6 }"/>
              </a-form-model-item> 
              </div>
          </div>                   
      </a-form-model>
      </a-modal>           
  </div>
</template>

<script>
import moment from 'moment';
import tabletitle from "../../../components/Title/tabletitle";
import headerTitle from "../../../components/Title/headerTitle";
import {getInformation,deleteInformation,addInformation,updateInformation,pushMsg} from "../../../api/sysmanage";

export default { 
  data() {
    return {
      name: this.$t('commonInfoSetting.a13'),
      visible: false,//添加与变更弹窗是否显示
      data: [],
      loading: false,      
      siteId:0,
      size:{ x: 1300, y:500},
      selectedOption:{},
      selectedOptions:[],
      selectedRowKeys:[],
      form: {
        siteId: 0,
        updateUser: "",
        info:"",
      },
      infoForm:{
        oldId:"0",//旧信息ID
        msg: "",//信息内容
        type:"1",//指南类型
        ranges:[moment(), moment().endOf('month')],//日期时间
        important: true,//是否重要信息
        isAdd:true,//是否新增
      },
      rules: {
        mainUser: [
          { required: true, message: 'Please input mainUser name', trigger: 'blur' },
        ],        
      },
      warningSetting:{},        
      pagination: {
        //分页需求配置
        total: 0, //数据总数
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => ` ${total} ${this.$t("commonInfoSetting.a14")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        // hideOnSinglePage:true,//只有一页时是否隐藏分页：默认值false
      },
      options: [
        {desc:this.$t('commonInfoSetting.a15'),type:"1",key:0},
        {desc:this.$t('commonInfoSetting.a16'),type:"2",key:1},
        {desc:this.$t('commonInfoSetting.a17'),type:"3",key:2},
        {desc:this.$t('commonInfoSetting.a18'),type:"4",key:3},
        {desc:this.$t('commonInfoSetting.a19'),type:"5",key:4},
        {desc:this.$t('commonInfoSetting.a20'),type:"6",key:5},
        {desc:this.$t('commonInfoSetting.a21'),type:"7",key:6},
        {desc:this.$t('commonInfoSetting.a22'),type:"8",key:7},
        {desc:this.$t('commonInfoSetting.a23'),type:"101",key:8}
      ],
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          width: 80,
          ellipsis: true,  
          // scopedSlots: { customRender: "id" },
        },
        {
          title: this.$t('commonInfoSetting.a8'),
          dataIndex: "infoKind",
          width: 80,
          ellipsis: true,          
        },
        {
          title: this.$t('commonInfoSetting.a30'),
          dataIndex: "addMod",
          width: 100,
          ellipsis: true,          
        },
        {
          title: this.$t('commonInfoSetting.a31'),
          dataIndex: "lastUpdate",
          width: 100,
          ellipsis: true,         
        },
        {
          title: this.$t('commonInfoSetting.a32'),
          dataIndex: "updateUser",
          width: 100,
          ellipsis: true,
        },   
        {
          title: this.$t('commonInfoSetting.a1'),
          dataIndex: "information",
          width: 100,
          ellipsis: true,
        },  
        {
          title: this.$t('commonInfoSetting.a33'),
          dataIndex: "showName",
          width: 100,
          ellipsis: true,
        },  
        {
          title: this.$t('commonInfoSetting.a34'),
          dataIndex: "dispStartDay",
          width: 100,
          ellipsis: true,
        },  
        {
          title: this.$t('commonInfoSetting.a35'),
          dataIndex: "dispEndDay",
          width: 100,
          ellipsis: true,
        },              
      ],
    };
  }, 
  mounted(){
    this.inquire();//退出更新窗口后，刷新数据 
  },
  computed:{
    allrowSelection() {
      return {
        selectedRowKeys:this.selectedRowKeys,
        selectedRows:this.selectedOptions,
        onChange: (selectedRowKeys, selectedRows) => {
          if(selectedRows!=null&&selectedRows.length>0){  
            this.selectedOptions = selectedRows;
            this.selectedOption = selectedRows[0];//取第一个选择项
            this.selectedRowKeys=selectedRowKeys;
          }
        },
      };
    },
  },
  methods: {  
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById("commonInfoSetting");
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName("main")[0].clientHeight;
        const title =
          indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
        const tableHeight = height - 66 - title;
        this.size.y = tableHeight;
      }
    },
    setValue(src){
      if(src==null){        
        this.infoForm.oldId = "0";
        this.infoForm.msg="";//信息内容
        this.infoForm.type="1";//指南类型
        this.infoForm.ranges[0]=moment();//开始时间
        this.infoForm.ranges[1]=moment().endOf('month');//结束时间
        this.infoForm.important=false;//是否重要信息
      }else{
        console.log("commonInfoSetting setValue:", src);
        this.infoForm.oldId = src.id;
        this.infoForm.msg=src.information;//信息内容
        this.infoForm.type=src.subCategoryType+"";//指南类型
        this.infoForm.ranges[0]=moment(src.dispStartDay);//开始时间
        this.infoForm.ranges[1]=moment(src.dispEndDay);//结束时间
        this.infoForm.important=src.significantMessage;//是否重要信息
      }
    },
    handleOk(){
      console.log("commonInfoSetting infoForm:", this.infoForm); 
      if(this.infoForm.msg==null||this.infoForm.msg==""){
        this.$message.info(this.$t('general.a15'));
        return;
      }
      if(this.infoForm.isAdd){//添加
        this.addInformation();
        this.pushMsg(); 
      }
      else{//变更
        this.updateInformation();
      }          
    } ,  
    pushMsg(){
      let par={
        type: 0,
        content:"test",
      }
      console.log("pushMsg:",par);
      pushMsg(par)
        .then((res) => {
          let { data } = res;
          // this.$message.info("推送消息成功"); 
        })
        .catch((err) => {
          console.log(err);
          // this.$message.error("推送消息失败"); 
        });   
    }, 
    onCancel(){

    },
    cancel(){
      
    },
    onDelete() {
      if(this.selectedOptions.length==0){
        this.$message.info(this.$t('general.a14'));
        return;
      }
      this.deleteInformation();  
         
    },    
    onAdd() { 
      this.visible = true;  
      this.setValue(null);//初始化页面关联对象
      this.infoForm.isAdd = true;    
    },  

    onChange() {  
      if(this.selectedOptions.length>1){
        this.$message.info(this.$t('downloadSetting.a24'));
        return;
      }
      this.visible = true;
      this.setValue(this.selectedOption);//初始化页面关联对象
      this.infoForm.isAdd = false;
    },  
    
    // 变更
    updateInformation() {       
      this.loading = true;  
      let par = {
          oldId:this.infoForm.oldId,//旧信息ID
          msg: this.infoForm.msg,//信息内容
          type:this.infoForm.type,//指南类型
          infoType:1,//1：公共指南；2：咨询处；3：继承事项；4：下载；
          from:moment(this.infoForm.ranges[0]).format("YYYY-MM-DD"),//开始时间
          to:moment(this.infoForm.ranges[1]).format("YYYY-MM-DD"),//结束时间
          juyokbn:this.infoForm.important?"1":"0",//是否重要信息
      };
      // console.log("updateInformation par:", par);     
      updateInformation(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          // console.log("updateInformation result", data);
          this.inquire();
          this.$message.info(this.$t('commonInfoSetting.a24')); 
          this.visible = false;  
          this.inquire();//退出更新窗口后，刷新数据
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('commonInfoSetting.a25')); 
        }); 
    },
    // 添加
    addInformation() {           
      this.loading = true;  
      let infoPar = {
          oldId:this.infoForm.oldId,//旧信息ID
          msg: this.infoForm.msg,//信息内容
          type:this.infoForm.type,//指南类型
          infoType:1,//1：公共指南；2：咨询处；3：继承事项；4：下载；
          from:moment(this.infoForm.ranges[0]).format("YYYY-MM-DD"),//开始时间
          to:moment(this.infoForm.ranges[1]).format("YYYY-MM-DD"),//结束时间
          juyokbn:this.infoForm.important?"1":"0",//是否重要信息
        
      };
      console.log("commonInfoSetting par:", infoPar); 
      addInformation(infoPar)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("insertCommonInfo result", data);
          if(data){            
            this.$message.info(this.$t('commonInfoSetting.a26')); 
            this.visible = false; 
            this.inquire();//退出更新窗口后，刷新数据
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('commonInfoSetting.a27')); 
        }); 
    },

    // 删除
    deleteInformation() {     
      this.loading = true;      
      let ids = "1";//初始化通知信息类型为1
      if(this.selectedOptions!=null&&this.selectedOptions.length>0){
        for(let i=0;i<this.selectedOptions.length;i++){
            ids = ids +","+this.selectedOptions[i].id; 
        }       
      }
      let par = {
        param: ids,
      };
      console.log("deleteInformation par", par);
      deleteInformation(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("deleteInformation result", data);
          this.$message.info(this.$t('commonInfoSetting.a28')); 
          this.inquire();//退出更新窗口后，刷新数据 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('commonInfoSetting.a29')); 
        }); 
    },    
    // 条件查询方法
    inquire() {        
      this.loading = true;
      this.tableSize()
      // this.selectedRowKeys = [];
      let par = {
        updateUser: this.form.updateUser,
        infoType:1,//1：公共指南；2：咨询处；3：继承事项；4：下载；
        info: this.form.info,
      };
      console.log("commonInfoSetting inquire par:", par); 
      getInformation(par)
        .then((res) => {
          console.log(res)
          this.loading = false;
          let { data } = res;
          console.log("getInformation data", data);
          for (let i = 0; i < data.length; i++) {
            data[i].key = i + 1;
          }
          this.data = data;
          
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },    
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#commonInfoSetting {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px 20px 0px 20px;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.main {
  width: 100%;
  height: calc(100% - 87px);
  overflow: auto;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
  white-space: nowrap;
}
.textarea{
  width: 560px
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
}
.but{
  width: 120px;
  margin-left: 15px;
}
</style>